/* eslint-disable no-case-declarations */
import cookie from "js-cookie";
import defaults from "store/defaults";
import config from "configuration";
import i18n from "config/i18n";
import { processUserData } from "utils/processUserData";

const GET_USER = "global/GET_USER";
const GET_USER_SUCCESS = "global/GET_USER_SUCCESS";
const GET_USER_FAIL = "global/GET_USER_FAIL";

const SUSPEND = "userpanel/settings/SUSPEND";
const SUSPEND_SUCCESS = "userpanel/settings/SUSPEND_SUCCESS";
const SUSPEND_FAIL = "userpanel/settings/SUSPEND_FAIL";

const SET_LANGUAGE = "global/SET_LANGUAGE";
const SET_LANGUAGE_SUCCESS = "global/SET_LANGUAGE_SUCCESS";
const SET_LANGUAGE_FAIL = "global/SET_LANGUAGE_FAIL";

const GET_STATISTICS_MAIN = "global/GET_STATISTICS_MAIN";
const GET_STATISTICS_MAIN_SUCCESS = "global/GET_STATISTICS_MAIN_SUCCESS";
const GET_STATISTICS_MAIN_FAIL = "global/GET_STATISTICS_MAIN_FAIL";

const UPDATE_STATISTICS_SELF_MESSAGES =
	"global/UPDATE_STATISTICS_SELF_MESSAGES";
const UPDATE_STATISTICS_SELF_NOTIFICATIONS =
	"global/UPDATE_STATISTICS_SELF_NOTIFICATIONS";
const UPDATE_STATISTICS_SELF = "global/UPDATE_STATISTICS_SELF";

const UPDATE_STATISTICS_SITE = "global/UPDATE_STATISTICS_SITE";

const SET_USER_STATUS = "global/SET_USER_STATUS";
const SET_USER_STATUS_SUCCESS = "global/SET_USER_STATUS_SUCCESS";
const SET_USER_STATUS_FAIL = "global/SET_USER_STATUS_FAIL";

const GET_USER_EMAIL = "global/GET_USER_EMAIL";
const GET_USER_EMAIL_SUCCESS = "global/GET_USER_EMAIL_SUCCESS";
const GET_USER_EMAIL_FAIL = "global/GET_USER_EMAIL_FAIL";

const SET_USER = "userpane/data/SET_USER";
const SET_USER_SUCCESS = "userpane/data/SET_USER_SUCCESS";
const SET_USER_FAIL = "userpane/data/SET_USER_FAIL";

const SET_FINGERPRINT = "global/SET_FINGERPRINT";

const SET_PWA_INSTALL_STATUS = "global/SET_PWA_INSTALL_STATUS";

const SET_PWA_INSTALL_EVENT = "global/SET_PWA_INSTALL_EVENT";

const SET_DARK_MODE_FROM_BROWSER = "global/SET_DARK_MODE_FROM_BROWSER";
const SET_DARK_MODE_BY_USER = "global/SET_DARK_MODE_BY_USER";

const UPDATE_PROFILE_DETAILS = "user/UPDATE_PROFILE_DETAILS";

const SET_GEOLOCATION = "userpane/data/SET_GEOLOCATION";

const GET_GEOLOCATION_INITIAL_DATA = "global/GET_GEOLOCATION_INITIAL_DATA";
const GET_GEOLOCATION_INITIAL_DATA_SUCCESS =
	"global/GET_GEOLOCATION_INITIAL_DATA_SUCCESS";
const GET_GEOLOCATION_INITIAL_DATA_FAIL =
	"global/GET_GEOLOCATION_INITIAL_DATA_FAIL";

const CLEAR_GEOLOCATION_INITIAL_DATA = "global/CLEAR_GEOLOCATION_INITIAL_DATA";

const SET_FIREBASE_ID = "global/SET_FIREBASE_ID";

const SET_USER_COUNTRY_FROM_IP = "global/SET_USER_COUNTRY_FROM_IP";

const SET_IS_CLIENT = "global/SET_IS_CLIENT";

const SET_USER_ONLINE = "user/SET_USER_ONLINE";

const SET_VIEW_TYPE = "global/SET_VIEW_TYPE";

const SET_HOMEPAGE_MEDIA_VIEW_TYPE = "global/SET_HOMEPAGE_MEDIA_VIEW_TYPE";

const initialState = defaults.global;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		/**
		 * Aktualizacja danych o aktualnie zalogowanym userze pochodzących z nagłówka
		 */
		case UPDATE_PROFILE_DETAILS:
			return {
				...state,
				user: {
					...state.user,
					frozen: action.userData.frozen,
					avatar: action.userData.avatar,
					privateData: action.userData.private_data,
					status_vip: action.userData.status_vip,
					searchSex: action.userData?.search_sex
						? Object.keys(action.userData.search_sex).filter(
								(item) => action.userData.search_sex[item]
						  )
						: [],
					awaitAccept: action.userData?.status?.await_accept ?? false,
					activityToken: action.userData?.activity_token,
				},
			};
		/**
		 * Pobranie danych o aktualnie zalogowanym userze
		 */
		case GET_USER:
			return {
				...state,
				user: {
					...state.user,
					loading: !action.setUser,
					loaded: false,
					success: false,
					set_user: {
						...state.user.set_user,
						loading: Boolean(action.setUser),
					},
				},
			};
		case GET_USER_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					loading: false,
					loaded: true,
					success: true,
					urlSearch: action.result.data.url_search,
					...processUserData(action),
					set_user: {
						loading: false,
						success: true,
					},
				},
				statisticsSelf: {
					...state.statisticsSelf,
					...action.result.data.statistics,
				},
			};
		case GET_USER_FAIL:
			return {
				...state,
				user: {
					...state.user,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		// -------------------------------------------
		case SUSPEND_SUCCESS: {
			return {
				...state,
				user: {
					...state.user,
					frozen: !state.user.frozen,
				},
			};
		}
		case SET_LANGUAGE:
			return {
				...state,
			};
		case SET_LANGUAGE_SUCCESS:
			if (action.setCookie) {
				cookie.set(
					config.language.cookieName,
					action.language,
					config.language.cookieOptionsJsCookie
				);
			}

			return {
				...state,
				language: action.language,
			};
		case SET_LANGUAGE_FAIL:
			return {
				...state,
			};
		case GET_STATISTICS_MAIN:
			return {
				...state,
				statisticsMain: {
					...state.statisticsMain,
				},
			};
		case GET_STATISTICS_MAIN_SUCCESS:
			return {
				...state,
				statisticsMain: {
					users: action.result.data.users,
					media: action.result.data.media,
				},
			};
		case GET_STATISTICS_MAIN_FAIL:
			return {
				...state,
				statistics: {
					...initialState.statisticsMain,
				},
			};
		case UPDATE_STATISTICS_SELF_MESSAGES:
			return {
				...state,
				statisticsSelf: {
					...state.statisticsSelf,
					messages: action.messages,
				},
			};
		case UPDATE_STATISTICS_SITE:
			return {
				...state,
				statisticsMain: {
					users: action.statistics?.main?.users || 0,
					media: action.statistics?.main?.media || 0,
				},
				statistics: {
					online: action.statistics?.online || 0,
					cams: action.statistics?.cams || 0,
				},
				hashUser: action.statistics?.userHash || "",
			};
		case UPDATE_STATISTICS_SELF_NOTIFICATIONS:
			const newNotifications =
				action.mode === true
					? state.statisticsSelf.notifications + 1
					: action.value;

			return {
				...state,
				statisticsSelf: {
					...state.statisticsSelf,
					notifications: newNotifications,
				},
			};
		case UPDATE_STATISTICS_SELF:
			const newNotificationsSelf =
				action.mode === true
					? state.statisticsSelf.notifications + 1
					: action.notifications === null
					? state.statisticsSelf.notifications
					: action.notifications;
			return {
				...state,
				statisticsSelf: {
					...state.statisticsSelf,
					loaded: true,
					likes: action.userpanel?.likes || 0,
					comments: action.userpanel?.comments || 0,
					invities: action.userpanel?.invities || 0,
					friends: action.userpanel?.friends || 0,
					visitors: action.userpanel?.visitors || 0,
					notifications: newNotificationsSelf || 0,
					messages:
						action.messages === null
							? state.statisticsSelf.messages
							: action.messages,
				},
			};
		case SET_USER_STATUS_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					profileStatus: action.status,
				},
			};
		case GET_USER_EMAIL_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					email: action.result.data?.email,
				},
			};
		case SET_FINGERPRINT:
			return {
				...state,
				fingerprint: action.fingerprint,
			};

		case SET_PWA_INSTALL_STATUS:
			return {
				...state,
				pwa: {
					...state.pwa,
					status: action.status,
				},
			};
		case SET_PWA_INSTALL_EVENT:
			return {
				...state,
				pwa: {
					...state.pwa,
					event: action.event,
				},
			};

		case SET_DARK_MODE_FROM_BROWSER:
			return {
				...state,
				darkMode: {
					...state.darkMode,
					darkMode: action.darkMode,
				},
			};
		case SET_DARK_MODE_BY_USER:
			return {
				...state,
				darkMode: {
					...state.darkMode,
					darkMode: action.darkMode,
					isChangedByUser: action.isChangedByUser,
				},
			};
		case SET_USER:
			return {
				...state,
				user: {
					...state.user,
					set_user: {
						loading: true,
						success: false,
					},
				},
			};
		case SET_USER_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					set_user: {
						loading: false,
						success: true,
					},
				},
			};
		case SET_USER_FAIL:
			return {
				...state,
				user: {
					...state.user,
					set_user: {
						loading: false,
						success: false,
					},
				},
			};
		case SET_GEOLOCATION:
			return {
				...state,
				geolocation: {
					...state.geolocation,
					accuracy: action.geolocation.accuracy,
					latitude: action.geolocation.latitude,
					longitude: action.geolocation.longitude,
					timestamp: action.geolocation.timestamp,
					error: action.geolocation.error,
				},
			};

		case GET_GEOLOCATION_INITIAL_DATA:
			return {
				...state,
				geolocation: {
					...state.geolocation,
					initialData: {
						loading: true,
					},
				},
			};
		case GET_GEOLOCATION_INITIAL_DATA_SUCCESS:
			return {
				...state,
				geolocation: {
					...state.geolocation,
					initialData: {
						loading: false,
						success: true,
						data: {
							city: action.result.data.city || [],
							region: action.result.data.region || [],
							country: action.result.data.country || [],
						},
					},
				},
			};
		case GET_GEOLOCATION_INITIAL_DATA_FAIL:
			return {
				...state,
				geolocation: {
					...state.geolocation,
					initialData: {
						loading: false,
						success: false,
						error: true,
					},
				},
			};
		case CLEAR_GEOLOCATION_INITIAL_DATA:
			return {
				...state,
				geolocation: {
					...state.geolocation,
					initialData: {
						loading: false,
						success: false,
						error: false,
						data: {
							city: [],
							region: [],
							country: [],
						},
					},
				},
			};

		case SET_FIREBASE_ID:
			return {
				...state,
				firebaseId: action.firebaseId,
			};

		case SET_USER_COUNTRY_FROM_IP:
			return {
				...state,
				userCountryFromIp: action.countryId,
			};

		case SET_IS_CLIENT:
			return {
				...state,
				isClient: action.isClient,
			};

		case SET_USER_ONLINE: {
			return {
				...state,
				user: {
					...state.user,
					online: action.online,
				},
			};
		}

		case SET_VIEW_TYPE:
			return {
				...state,
				viewType: action.viewType,
			};

		case SET_HOMEPAGE_MEDIA_VIEW_TYPE:
			return {
				...state,
				homepageMediaType: action.homepageMediaType,
			};

		default:
			return state;
	}
}

export function getUser(setUser = false) {
	return {
		types: [GET_USER, GET_USER_SUCCESS, GET_USER_FAIL],
		promise: (client) => client.get("/user"),
		setUser,
	};
}

/**
 * Zawiesza/odwiesza konto użytkownika
 * @api suspendUser
 * @param {String} password hasło do konta
 * @param {Boolean} newSuspendedStatus nowy status zawieszenia
 */
export function suspendAccount(password) {
	return {
		types: [SUSPEND, SUSPEND_SUCCESS, SUSPEND_FAIL],
		promise: (client) =>
			client.put(`/setting/suspend/web`, {
				password,
			}),
	};
}

export function setLanguage({ language, auth, setCookie }) {
	const finalLanguage = language ? language.slice(0, 2) : "pl";

	if (auth) {
		return {
			types: [SET_LANGUAGE, SET_LANGUAGE_SUCCESS, SET_LANGUAGE_FAIL],
			promise: (client) => {
				return i18n.changeLanguage(finalLanguage).then(() => {
					return client.put(`/setting/language`, null, {
						params: {
							lang: finalLanguage,
						},
					});
				});
			},
			language: finalLanguage,
			setCookie,
		};
	}

	return {
		types: [SET_LANGUAGE, SET_LANGUAGE_SUCCESS, SET_LANGUAGE_FAIL],
		promise: (client) => {
			client.modifyLanguageHeader(finalLanguage);
			return i18n.changeLanguage(finalLanguage);
		},
		language: finalLanguage,
		setCookie,
	};
}

export function getStatisticsMain() {
	return {
		types: [
			GET_STATISTICS_MAIN,
			GET_STATISTICS_MAIN_SUCCESS,
			GET_STATISTICS_MAIN_FAIL,
		],
		promise: (client) => client.get(`/datezone/statistics/main`),
	};
}

export function updateStatisticsSelfMessages(messages) {
	return {
		type: UPDATE_STATISTICS_SELF_MESSAGES,
		messages,
	};
}

export function updateStatisticsSelfNotifications(mode, value = 0) {
	return {
		type: UPDATE_STATISTICS_SELF_NOTIFICATIONS,
		mode,
		value,
	};
}

export function updateStatisticsSelfAuthorized(
	mode,
	userpanel,
	messages = 0,
	notifications = 0
) {
	return {
		type: UPDATE_STATISTICS_SELF,
		mode,
		userpanel,
		messages,
		notifications,
	};
}

export function updateStatisticsSite(statistics) {
	return {
		type: UPDATE_STATISTICS_SITE,
		statistics,
	};
}

export function setUserStatus(status) {
	return {
		types: [SET_USER_STATUS, SET_USER_STATUS_SUCCESS, SET_USER_STATUS_FAIL],
		promise: (client) => client.put(`/user/status?status=${status}`),
		status,
	};
}

export function getEmail() {
	return {
		types: [GET_USER_EMAIL, GET_USER_EMAIL_SUCCESS, GET_USER_EMAIL_FAIL],
		promise: (client) => client.get("/user/email"),
	};
}

export function setFingerprint(hash) {
	return {
		type: SET_FINGERPRINT,
		fingerprint: hash,
	};
}

export function setPWAInstallStatus(status) {
	return {
		type: SET_PWA_INSTALL_STATUS,
		status,
	};
}

export function setPWAInstallEvent(event) {
	return {
		type: SET_PWA_INSTALL_EVENT,
		event,
	};
}

export function setDarkModeFromBrowser(darkMode) {
	return {
		type: SET_DARK_MODE_FROM_BROWSER,
		darkMode,
	};
}

export function setDarkModeByUser(darkMode, isChangedByUser = true) {
	return {
		type: SET_DARK_MODE_BY_USER,
		darkMode,
		isChangedByUser,
	};
}

export function setUserData(sex, state) {
	let data = {
		country: state.location.country,
		region: state.location.region,
		city: state.location.city,
		lang: state.lang,
		name: state.name,
		height: state.height,
		figure: state.figure,
		hair: state.hair,
		relationship: state.relationship,
		kids: state.kids,
		drink: state.drink,
		smoke: state.smoke,
		education: state.education,
		preferences: state.preferences,
		description: state.description ?? "",
		search_sex: state.search_sex,
		search_age_min: state.search_age?.from ?? null,
		search_age_max: state.search_age?.to ?? null,
		purpose: state.purpose,
	};
	if (sex === "couple") {
		data = Object.assign(data, {
			name_para: state.name_para,
			height_para: state.height_para,
			figure_para: state.figure_para,
			hair_para: state.hair_para,
			drink_para: state.drink_para,
			smoke_para: state.smoke_para,
			education_para: state.education_para,
		});
	}
	return {
		types: [SET_USER, SET_USER_SUCCESS, SET_USER_FAIL],
		promise: (client) =>
			client.put(`/user`, data, {
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
	};
}

/**
 * Aktualizuje dane o profilu usera pochodzące z nagłówka
 * @param {JSON} user
 * @returns
 */
export function updateProfileDetails(user) {
	return {
		type: UPDATE_PROFILE_DETAILS,
		userData: user,
	};
}

export function setGeolocation(geolocation) {
	return {
		type: SET_GEOLOCATION,
		geolocation,
	};
}
export function getGeolocationInitialData(latitude, longitude, extend = true) {
	return {
		types: [
			GET_GEOLOCATION_INITIAL_DATA,
			GET_GEOLOCATION_INITIAL_DATA_SUCCESS,
			GET_GEOLOCATION_INITIAL_DATA_FAIL,
		],
		promise: (client) =>
			client.post(`datezone/autocomplete/coords`, {
				lat: latitude,
				lng: longitude,
				extend,
			}),
	};
}

export function clearGeolocationInitialData() {
	return {
		type: CLEAR_GEOLOCATION_INITIAL_DATA,
	};
}

export function setFirebaseId(fireabseId) {
	return {
		type: SET_FIREBASE_ID,
		fireabseId,
	};
}

export function setUserCountryFromIp(countryId) {
	return {
		type: SET_USER_COUNTRY_FROM_IP,
		countryId,
	};
}

export function setIsClient(isClient) {
	return {
		type: SET_IS_CLIENT,
		isClient,
	};
}

export function setUserOnline(online) {
	return {
		type: SET_USER_ONLINE,
		online,
	};
}

export function setViewType(viewType) {
	return {
		type: SET_VIEW_TYPE,
		viewType,
	};
}

export function setHomepageMediaType(homepageMediaType) {
	return {
		type: SET_HOMEPAGE_MEDIA_VIEW_TYPE,
		homepageMediaType,
	};
}
