/* eslint-disable no-param-reassign */
import { createElement } from "react";
import styled, { css } from "styled-components";
import {
	resolveProps,
	marginable,
	fontable,
} from "styles/styledComponentsMixins";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Text = styled(({ block, maxContent, ...props }) =>
	createElement("span", resolveProps(props, marginable, fontable))
)`
	${marginable.css}
	${fontable.css}
	display: ${({ block }) => (block ? "inline-block" : "inline")};

	${({ maxContent }) =>
		maxContent &&
		css`
			width: max-content;
		`}

	${({ space }) =>
		space &&
		css`
			margin-right: 0.25em;
		`}

	${({ spaceLeft }) =>
		spaceLeft &&
		css`
			margin-left: 0.25em;
		`}
`;

Text.defaultProps = {
	...marginable.defaultProps,
	...fontable.defaultProps,
};

Text.propTypes = {
	...marginable.propTypes,
	...fontable.defaultProps,
};

Text.displayName = "Text";

export default Text;
