const dt30Cookie = new Date();
dt30Cookie.setDate(dt30Cookie.getDate() + 365);

export default {
	host: process.env.HOST || "localhost",
	port: process.env.PORT,

	auth: {
		cookieName: "auth",
		cookieOptionsJsCookie: {
			path: "/",
			expires: 365,
		},
		cookieOptionsExpress: {
			expires: dt30Cookie,
			httpOnly: false,
		},
	},

	language: {
		cookieName: "i18next",
		cookieOptionsJsCookie: {
			path: "/",
			expires: 365,
		},
		cookieOptionsExpress: {
			expires: dt30Cookie,
			httpOnly: false,
		},
	},

	fingerprint: {
		cookieName: "fpDatezone",
		cookieOptionsJsCookie: {
			path: "/",
			expires: 365,
		},
		cookieOptionsExpress: {
			expires: dt30Cookie,
			httpOnly: false,
		},
	},

	theme: {
		cookieName: "themeDatezone",
		cookieOptionsJsCookie: {
			path: "/",
			expires: 365,
		},
		cookieOptionsExpress: {
			expires: dt30Cookie,
			httpOnly: false,
		},
	},

	app: {
		head: {
			meta: {
				title: {
					name: "title",
					content: "Sex randki bez zobowiązań Datezone.com",
				},
				description: {
					name: "description",
					content:
						"Umawiaj się na sex spotkania albo znajdź partnera na dłużej! Ponad 1,0 mln realnych osób na Datezone. Wejdź i sprawdź za darmo!",
				},
				type: { name: "type", content: "website" },
				url: { name: "url", content: "https://www.datezone.com/" },
				image: {
					name: "image",
					content:
						"https://imgcdn.datezone.com/static/img/layout/openGraph/logo_og.jpg?v=1",
				},
			},
		},
	},

	firebase: {
		config: {
			apiKey: process.env.RAZZLE_FIREBASE_CONFIG_API_KEY,
			authDomain: process.env.RAZZLE_FIREBASE_CONFIG_AUTH_DOMAIN,
			databaseURL: process.env.RAZZLE_FIREBASE_CONFIG_DATABASE_URL,
			projectId: process.env.RAZZLE_FIREBASE_CONFIG_PROJECT_ID,
			storageBucket: process.env.RAZZLE_FIREBASE_CONFIG_STORAGE_BUCKET,
			messagingSenderId:
				process.env.RAZZLE_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
			appId: process.env.RAZZLE_FIREBASE_CONFIG_APP_ID,
		},
		vapidKey: process.env.RAZZLE_FIREBASE_VAPIDKEY,
		localStorage: {
			itemName: "firebaseData",
		},
	},

	MODE: {
		__DEVELOPMENT__: process?.env?.NODE_ENV === "development" ?? false,
		__PRODUCTION__: process?.env?.NODE_ENV === "production" ?? false,
	},
};
