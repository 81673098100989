module.exports = {
	global: {
		userAgent: {
			ua: "",
			browser: {
				name: "",
				version: "",
				major: "",
			},
			engine: {
				name: "",
				version: "",
			},
			os: {
				name: "",
				version: "",
			},
			device: {
				vendor: "",
				model: "",
				type: "",
			},
			cpu: {
				architecture: "",
			},
		},
		language: "pl",
		viewType: undefined,
		homepageMediaType: "any",
		darkMode: {
			darkMode: false,
			isChangedByUser: false,
		},
		geolocation: {
			accuracy: null,
			latitude: null,
			longitude: null,
			timestamp: null,
			error: null,
			initialData: {
				loading: false,
				loaded: false,
				error: false,
				data: {
					city: [],
					region: [],
					country: [],
				},
			},
		},
		firebaseId: null,
		hashUser: null,
		userCountryFromIp: null,
		isClient: false,
		// User globalne dane zapisywane w lokal storage o aktualnie zalogowanym userze
		user: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			id: null,
			acceptedFields: {
				city: false,
				description: false,
				name: false,
				nameCouple: false,
			},
			awaitAccept: false,
			activityToken: null,
			activityUrl: null,
			allowLinks: false,
			frozen: false,
			avatar: "",
			avatarMedia: {
				file: undefined,
				type: undefined,
				contentId: undefined,
				width: undefined,
				height: undefined,
			},
			login: "",
			status_vip: 0,
			online: 0,
			profileStatus: 0,
			privateData: false,
			city: null,
			region: null,
			country: null,
			searchSex: [],
			searchAge: [],
			searchFor: [],
			sex: null,
			sound_enable: false,
			urlSearch: "",
			relationship: null,
			languages: [],
			cityName: "",
			regionName: "",
			email: "",
			kids: 0,
			partners: {
				birthday: [],
				sex: [],
				name: [],
				height: [180, 180],
				figure: [],
				zodiak: [],
				hair: [],
				alko: [],
				smoke: [],
				education: [],
			},
			created: null,
			preferences: [],
			description: {
				parsed: [],
				plain: "",
			},
			post_left: {
				date: null,
				limit: 0,
				max: 0,
			},
			vip_left: {
				date: null,
				time: null,
			},
			set_user: {
				loading: false,
				success: false,
			},

			complete: {},

			suspension: {
				duration: 0,
				to: -1,
				code: -1,
			},
		},
		statistics: {
			online: 0,
			cams: 0,
		},
		statisticsMain: {
			users: 0,
			media: 0,
		},
		statisticsSelf: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			lastUpdate: false,
			likes: 0,
			comments: 0,
			invities: 0,
			friends: 0,
			visitors: 0,
			messages: 0,
			notifications: 0,
		},
		fingerprint: null,
		pwa: {
			status: {
				isInstallAllowed: false,
				isInstallWatingConfirm: false,
				isInstalling: false,
				isInstallCancelled: false,
				isInstallSuccess: false,
				isInstallFailed: false,
			},
			event: null,
		},
		savedPositionsScrollY: [],
	},
	auth: {
		login: {
			loading: false,
			loaded: false,
			error: false,
			errorMessage: null,
		},
		register: {
			registerForm: {
				terms: false,
				gender: {
					coupleCase: false,
					gender: null,
				},
				genderCoupleCase: {
					partner1: null,
					partner2: null,
				},
				age: {
					day: null,
					month: null,
					year: null,
					isSelected: false,
				},
				ageCoupleCase: {
					partner1: { day: null, month: null, year: null },
					partner2: { day: null, month: null, year: null },
					isSelectedPartner1: false,
					isSelectedPartner2: false,
				},
				login: {
					login: "",
					errorFrontend: false,
					loginAvailable: false,
					loadingApi: false,
					successApi: false,
					errorApi: false,
					errorApiMessage: null,
				},
				email: {
					email: null,
					errorFrontend: false,
					emailAvailable: false,
					loadingApi: false,
					successApi: false,
					errorApi: false,
					errorApiMessage: null,
				},
				password: {
					password: null,
					errorFrontend: false,
				},
				location: {
					city: null,
					region: null,
					country: null,
				},
			},
			loading: false,
			loaded: false,
			error: false,
		},
		recover: { loading: false, loaded: false, error: false },
		fakeUserData: {
			avatar: null,
			city: null,
			region: null,
			country: null,
			login: null,
			sex: null,
			userData: {
				age: null,
				birthday: null,
			},
		},
		sentSmsCodeTime: null,
		blockTime: null,
		countryCode: null,
		phoneNubmer: null,
		isoCountryCode: null,
		token: null,
		tempToken: null,
		jwt: null,
		isAuthFromServer: false,
	},
	user: {
		main: {
			loading: false,
			loaded: false,
			loadedFull: false,
			error: false,
			frozen: false,
			errorType: null,
			login: null,
			id: null,
			avatar: "",
			sex: null,
			languages: [],
			location: {
				city: null,
				region: null,
				country: null,
			},
			preferences: [],
			relationship: null,
			description: "",
			create: null,
			vip: 1,
			online: 0,
			profileStatus: 0,
			privateData: false,
			kids: 0,
			statistics: {
				userImages: 0,
				userVideos: 0,
				userCamera: false,
				userFriends: 0,
				likes: 0,
				followers: 0,
				following: 0,
			},
			search: {
				search_sex: [],
				search_age: {
					from: 0,
					to: 0,
				},
				search_for: [],
			},
			camera: {
				id: false,
				viewers: 0,
			},
			partners: {
				sex: [],
				name: [],
				height: [],
				figure: [],
				zodiak: [],
				hair: [],
				alko: [],
				smoke: [],
				education: [],
				age: [],
			},
			status: {
				blockByMe: false,
				blockMe: false,
				followStatus: 0,
				friendStatus: 0,
				is_report: false,
			},
			tolerances: {
				canComment: false,
				canDeleteFriend: false,
				canFollow: false,
				canInviteFriend: false,
				canLike: false,
				canSendMessage: false,
				canUnfollow: false,
				filterUserSex: false,
				limit: undefined,
			},
			fromServer: false,
			locationMd5Hash: "",
		},
		galleries: {
			albums_list: {
				loading: false,
				loaded: false,
				data: [],
			},
			current_album: {
				loading: false,
				loaded: false,
			},
		},
		images: {
			albums_list: {
				loading: false,
				loaded: false,
				data: [],
			},
			current_album: {
				loading: false,
				loaded: false,
			},
		},
		videos: {
			albums_list: {
				loading: false,
				loaded: false,
				data: [],
			},
			current_album: {
				loading: false,
				loaded: false,
			},
		},
		friends: {
			current: {
				loading: false,
				loaded: false,
				success: false,
				error: false,
				list: [],
				pagination: {
					totalPages: 1,
					currentPage: 1,
				},
			},
			followers: {
				loading: false,
				loaded: false,
				success: false,
				error: false,
				list: [],
				pagination: {
					totalPages: 1,
					currentPage: 1,
				},
			},
			following: {
				loading: false,
				loaded: false,
				success: false,
				error: false,
				list: [],
				pagination: {
					totalPages: 1,
					currentPage: 1,
				},
			},
		},
		media: {
			loading: true,
			loaded: false,
			error: false,
			total: 0,
			loadedCount: 0,
			data: [],
			pagination: {
				totalPages: 0,
				currentPage: 0,
			},
		},
		gallery: {
			loading: true,
			loaded: false,
			error: false,
			data: [],
		},
		suspension: {
			duration: 0,
			to: -1,
			code: -1,
		},
	},

	mainPages: {
		landing: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			lang: null,
			data: {
				images: [],
				locationsSeo: [],
				preferencesSeo: [],
				randomVideoIndex: null,
				news: [],
			},
		},
		locationSeo: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: {
				locations: [],
			},
		},
		preferencesSeo: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: {
				preferences: [],
			},
		},
		home: {
			loading: false,
			success: false,
			error: false,
			lang: null,
			data: {
				cameras: [],
				latest: [],
				notifications: [],
				news: [],
				photos: [],
				posts: [],
				ranking: [],
				videos: [],
				gallery: [],
			},
		},
		users: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: [],
			location: {
				country: null,
				region: null,
				city: null,
			},
			location_search: {
				country: null,
				countries: null,
				region: null,
				regions: null,
				city: null,
				cities: null,
			},
			pagination: {
				currentpage: 1,
				totalpages: 1,
			},
			fromServer: false,
			locationMd5Hash: "",
		},
		latest: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: [],
			pagination: {
				currentpage: 1,
				totalpages: 1,
			},
		},
		rankings: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: [],
			location: {
				country: null,
				region: null,
			},
			pagination: {
				currentpage: 1,
				totalpages: 1,
			},
			fromServer: false,
			locationMd5Hash: "",
		},
		online: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: [],
			pagination: {
				currentpage: 1,
				totalpages: 1,
			},
		},
		search: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: [],
			total: 0,
			pagination: {
				totalpages: 1,
				currentpage: 0,
			},
		},
		photos: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: [],
			location: {
				city: "",
				region: "",
				country: "",
			},
			pagination: {
				currentpage: 1,
				totalpages: 1,
			},
			locationMd5Hash: "",
		},
		videos: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: [],
			location: {
				city: "",
				region: "",
				country: "",
			},
			pagination: {
				currentpage: 1,
				totalpages: 1,
			},
			locationMd5Hash: "",
		},
		gallery: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: [],
			location: {
				city: "",
				region: "",
				country: "",
			},
			pagination: {
				currentpage: 1,
				totalpages: 1,
			},
			locationMd5Hash: "",
		},
		cameras: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: [],
			pagination: {
				currentpage: 1,
				totalpages: 1,
			},
		},
		posts: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			data: [],
			location: {
				city: "",
				region: "",
				country: "",
			},
			pagination: {
				currentpage: 1,
				totalpages: 1,
			},
			locationMd5Hash: "",
		},
		news: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			lang: null,
			data: [],
			pagination: {
				currentpage: 1,
				totalpages: 1,
			},
		},
		singleNews: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
			lang: null,
			data: [],
		},
		contact: {
			loading: false,
			loaded: false,
			success: false,
			error: false,
		},
		support: {
			loading: false,
			loaded: false,
			error: false,
			lang: null,
			title: "",
			data: [],
		},
		privacy: {
			loading: false,
			loaded: false,
			error: false,
			lang: null,
			title: "",
			data: [],
			date: [],
		},
		terms: {
			loading: false,
			loaded: false,
			error: false,
			lang: null,
			title: "",
			data: [],
			date: [],
		},
		app: {
			loading: false,
			loaded: false,
			error: false,
			data: {},
		},
	},

	userpanel: {
		// userpanel friends
		friends: {
			current: {
				whileRemoving: false,
				loading: false,
				loaded: false,
				total: null,
				users: [],
				pagination: null,
			},
			invitations: {
				whileAccepting: false,
				whileRejecting: false,
				loading: false,
				loaded: false,
				total: null,
				users: [],
				pagination: null,
			},
			invitationsSend: {
				whileRemoving: false,
				loading: false,
				loaded: false,
				total: null,
				users: [],
				pagination: null,
			},
			followers: {
				whileRemoving: false,
				loading: false,
				loaded: false,
				total: null,
				users: [],
				pagination: null,
			},
			following: {
				whileRemoving: false,
				loading: false,
				loaded: false,
				total: null,
				users: [],
				pagination: null,
			},
		},

		// userpanel notifications
		notifications: {
			received: {
				loading: false,
				loaded: false,
				data: [],
				total: 0,
				pagination: {
					totalpages: 1,
					currentpage: 1,
				},
			},
			activity: {
				whileRemoving: false,
				loading: false,
				loaded: false,
				total: 0,
				data: [],
				pagination: {
					totalpages: 1,
					currentpage: 1,
				},
			},
			preferences: {
				loading: false,
				loaded: false,
				success: false,
				selected: [],
			},
			pushNotifications: {
				other: false,
			},
		},

		// userpanel payment
		payment: {
			info: {
				loading: false,
				currency: null,
				currentStatus: 1,
				amount: {
					vip: {},
					super_vip: {},
				},
				left: {
					date: null,
					time: null,
				},
			},
		},

		// userpanel settings
		settings: {
			preferences: {
				loading: false,
				loaded: false,
				searchSex: [],
				allowLike: false,
				allowComment: false,
				allowInvite: false,
				allowConversations: false,
				allowFollowing: false,
				allowVisitorsWhoIamSearch: false,
				notificationsPlaySounds: false,
				notificationsDailySummary: false,
				pushMessagesNotifications: false,
				pushOtherNotifications: false,
				verifyVipShowMyStatus: {
					enabled: false,
					status: false,
				},
				verifyVipShowMyAge: {
					enabled: false,
					status: false,
				},
				verifyVipRenewVipAutomatically: {
					enabled: false,
					status: false,
				},
			},
			blocked: {
				loading: false,
				loaded: false,
				users: [],
				pagination: {},
			},

			showAge: true,
			showStatusVip: true,
			soundEnable: true,
			whileSuspendingAccount: false,
			whileDeletingAccount: false,
			whileEmailChanging: false,
			whilePasswordChanging: false,
		},

		// userpanel photos
		photos: {
			photos: {
				loading: false,
				success: false,
				error: false,
				whileRemoving: false,
				photos: [],
				pagination: null,
			},
			editPhoto: {
				info: {
					loading: false,
					loaded: false,
					success: false,
					error: false,
					image: "",
					description: [],
					description_raw: "",
					desctiption_edit: [],
					album: "",
					album_name: [],
					private: false,
					photos_in_album: 0,
					media_in_album: 0,
					date_add: "",
					count_like: 0,
					count_comment: 0,
					i_liked: false,
					i_commented: false,
				},
				likes: {
					loading: false,
					loaded: false,
					success: false,
					error: false,
					likes: [],
					pagination: { totalpages: 0 },
				},
				comments: {
					loading: false,
					loaded: false,
					success: false,
					error: false,
					comments: [],
					pagination: { totalpages: 0 },
				},
			},
		},

		// userpanel videos
		videos: {
			videos: {
				loading: false,
				success: false,
				error: false,
				whileRemoving: false,
				videos: [],
				pagination: null,
			},
			editVideo: {
				info: {
					loading: false,
					loaded: false,
					success: false,
					error: false,
					video: "",
					description: [],
					description_raw: "",
					desctiption_edit: [],
					album: "",
					album_new: "",
					album_name: [],
					private: false,
					videos_in_album: 0,
					media_in_album: 0,
					date_add: "",
					count_like: 0,
					count_comment: 0,
					i_liked: false,
					i_commented: false,
				},
				likes: {
					loading: false,
					loaded: false,
					success: false,
					error: false,
					likes: [],
					pagination: { totalpages: 0 },
				},
				comments: {
					loading: false,
					loaded: false,
					success: false,
					error: false,
					comments: [],
					pagination: { totalpages: 0 },
				},
			},
		},
		posts: {
			listing: {
				loading: false,
				loaded: false,
				success: false,
				error: false,
				data: [],
				location: {
					city: "",
					region: "",
					country: "",
				},
				pagination: {
					totalpages: 1,
					currentpage: 1,
				},
			},
			editPost: {
				info: {
					loading: false,
					loaded: false,
					success: false,
					error: false,
					text: [],
					text_raw: "",
					text_edit: [],
					private: false,
					date_add: "",
					count_like: 0,
					count_comment: 0,
					i_liked: false,
					i_commented: false,
				},
				likes: {
					loading: false,
					loaded: false,
					success: false,
					error: false,
					likes: [],
					pagination: { totalpages: 0 },
				},
				comments: {
					loading: false,
					loaded: false,
					success: false,
					error: false,
					comments: [],
					pagination: { totalpages: 0 },
				},
			},
		},
		links: {
			listing: {
				loading: false,
				loaded: false,
				success: false,
				error: false,
				data: [],
				pagination: {
					totalpages: 1,
					currentpage: 1,
				},
			},
			editLinks: {
				info: {
					loading: false,
					loaded: false,
					success: false,
					error: false,
					text: [],
					text_raw: "",
					text_edit: [],
					type: false,
				},
			},
		},
	},

	messages: {
		amount: {
			new: 0,
			dialog: 0,
		},
		pushNotifications: {
			messages: false,
		},
		allConversations: {
			whileRemoving: false,
			loading: false,
			loaded: false,
			total: 0,
			data: [],
			pagination: {
				currentpage: 0,
				totalpages: 0,
			},
		},
		newConversations: {
			whileRemoving: false,
			loading: false,
			loaded: false,
			total: 0,
			data: [],
			pagination: {
				currentpage: 0,
				totalpages: 0,
			},
		},
		sentConversations: {
			whileRemoving: false,
			loading: false,
			loaded: false,
			total: 0,
			data: [],
			pagination: {
				currentpage: 0,
				totalpages: 0,
			},
		},
		conversations: {},
	},
	modal: {
		userInfo: {
			loading: false,
			error: false,
			// success: false,
			data: {
				userId: null,
				avatar: null,
				login: "",
				statusVip: null,
				online: null,
				profileStatus: null,
				privateData: null,
			},
		},
		list: {
			loading: false,
			success: false,
			error: false,
			data: [],
			meta: {
				left_amount: -1,
				left_hash: null,
				right_amount: -1,
				right_hash: null,
				total: -1,
			},
		},
		contentInfo: {
			loading: false,
			success: false,
			error: false,
			data: {
				type: null,
				contentId: null,
				userId: null,
				albumId: null,
				albumName: null,
				private: null,
				videoUrl: null,
				images: {
					big: "",
					small: "",
				},
				description: "",
				dateAdd: null,
				countComment: null,
				countLike: null,
				width: null,
				height: null,
				filterLike: null,
				filterComment: null,
				canLike: null,
				canComment: null,
				limit: null,
				iLiked: null,
			},
		},
		visitedContent: {
			data: [],
			length: 0,
		},
		comments: {
			loading: false,
			success: false,
			totalPages: 0,
			comments: [],
			loadedComments: 0,
			totalComments: 0,
			whileAdding: false,
		},
		mainMediaInfo: {
			likesCount: null,
			contentId: null,
		},
	},
};
